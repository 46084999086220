import type { RefObject } from 'preact';
import { useEffect } from 'preact/hooks';

export const useLeftPadding = (wrapperRef: RefObject<HTMLDivElement>, itemsRef: RefObject<HTMLDivElement>) => {
    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            if (wrapperRef.current && itemsRef.current) {
                const { left } = wrapperRef.current.getBoundingClientRect();

                itemsRef.current.style.paddingLeft = `${left}px`;
            }
        });

        resizeObserver.observe(document.body);

        return () => {
            resizeObserver.disconnect();
        };
    }, [wrapperRef, itemsRef]);
};
