import type { RefObject } from 'preact';
import { useCallback, useEffect, useRef } from 'preact/hooks';

export const useDragScroll = (slider: RefObject<HTMLElement>) => {
    const isDown = useRef(false);
    const startX = useRef(0);
    const scrollLeft = useRef(0);

    const handleMouseDown = useCallback(
        (e: MouseEvent) => {
            if (!slider.current) return;

            isDown.current = true;
            startX.current = e.pageX - slider.current.offsetLeft;
            scrollLeft.current = slider.current.scrollLeft;
            slider.current.style.cursor = 'grabbing';
        },
        [slider],
    );

    const handleMouseUp = useCallback(() => {
        if (!slider.current) return;

        isDown.current = false;
        slider.current.style.cursor = 'grab';
    }, [slider]);

    const handleMouseMove = useCallback(
        (e: MouseEvent) => {
            if (!isDown.current || !slider.current) return;

            e.preventDefault();

            const x = e.pageX - slider.current.offsetLeft;
            const scrollOffset = x - startX.current;

            slider.current.scrollLeft = scrollLeft.current - scrollOffset;
        },
        [slider],
    );

    useEffect(() => {
        const sliderElement = slider.current;

        if (!sliderElement) return;

        sliderElement.addEventListener('mousedown', handleMouseDown);
        sliderElement.addEventListener('mouseleave', handleMouseUp);
        sliderElement.addEventListener('mouseup', handleMouseUp);
        sliderElement.addEventListener('mousemove', handleMouseMove);

        return () => {
            sliderElement.removeEventListener('mousedown', handleMouseDown);
            sliderElement.removeEventListener('mouseleave', handleMouseUp);
            sliderElement.removeEventListener('mouseup', handleMouseUp);
            sliderElement.removeEventListener('mousemove', handleMouseMove);
        };
    }, [slider, handleMouseDown, handleMouseUp, handleMouseMove]);
};
